import React, { useEffect, useState } from "react";

interface SinglePhotoUploadProps {
  options: {
    name: string;
    label?: string;
    currentPhoto?: string | null; // URL of the current photo
    height?: number;
    onPhotoChange: (name: string, file: File) => void;
  };
}

const SinglePhotoUpload: React.FC<SinglePhotoUploadProps> = ({ options }) => {
  const [currentPhoto, setCurrentPhoto] = useState<string | null>(null); // For the current photo URL
  const [uploadedPhoto, setUploadedPhoto] = useState<File | null>(null); // For the newly uploaded photo

  useEffect(() => {
    if (options.currentPhoto) {
      setCurrentPhoto(options.currentPhoto); // Initialize current photo from props
    }
  }, [options.currentPhoto]);

  const handleAddImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const fileInput = document.getElementById(`${options.name}Upload`) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const onPhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setUploadedPhoto(file); // Update the state for the newly uploaded photo
      options.onPhotoChange(e.target.name, file); // Trigger the callback with the file
    }
  };

  const renderPreview = () => {
    if (uploadedPhoto) {
      // Display the uploaded photo if it exists
      return <img height={options.height || 150} src={URL.createObjectURL(uploadedPhoto)} alt="Uploaded file preview" />;
    } else if (currentPhoto) {
      // Display the current photo URL if no uploaded photo exists
      return <img height={options.height || 150} src={currentPhoto} alt="Current file preview" />;
    }
    return null; // No photo to display
  };

  return (
    <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
      <label className="fw-semibold">{options.label || ""}</label>
      <div className="product-image w-100 position-relative">
        <div className="uploaded-images-container">
          <div className="uploaded-image">{renderPreview()}</div>
          <div className="add-image-button d-flex justify-content-start">
            <button onClick={handleAddImage}>+</button>
            <input
              id={`${options.name}Upload`}
              hidden
              type="file"
              name={options.name}
              onChange={onPhotoChange}
              accept="image/jpeg, image/png, image/gif, image/webp"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePhotoUpload;