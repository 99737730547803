import React, { useEffect, useState } from "react";
import {
    export_product_templates,
    fetch_product_templates,
    API_HOST,
} from "../../../../services/admin/products.ts";
import ItemListingTable, {
    getTableUpdateCallback,
} from "../../common/ItemListingTable";
import { useSearchParams } from "react-router-dom";
import { notify_promise } from "../../../../services/utils/toasts";
import ImportFilePopup from "../../common/ImportFilePopup";
import ListingPageControls from "../../common/ListingPageControls";
import { delete_records } from "../../../../services/dynamic-form.ts";

const FORM_KEY = "product-template";

const Page = () => {
    const [products, setProducts] = useState([]);
    const [fields, setFields] = useState([]);
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("search"));

    const [latestSearchQuery, setLatestSearchQuery] = useState(
        searchParams.get("search")
    );

    const [perPage, setPerPage] = useState(10);

    const apiCall = [
        fetch_product_templates,
        { page: 0, search: search ? search : "", perPage: perPage },
    ];

    const deleteItem = (id) => {
        notify_promise(
            new Promise((resolve, reject) => {
                delete_records(FORM_KEY, [id])
                    .then((res) => {
                        setProducts((prevProducts) => {
                            return prevProducts.filter((p) => {
                                return p.id !== id;
                            });
                        });
                        resolve(res);
                    })
                    .catch(reject);
            })
        );
    };

    const updateTableInfo = getTableUpdateCallback({
        apiCall: apiCall,
        dataSetter: setProducts,
        paginationSetter: setPagination,
        fieldSetter: setFields,
        buttons: [
            {
                template: "view",
                getRedirectURL: (id) => {
                    return `/admin/view/${FORM_KEY}/:id`
                },
            },
            {
                template: "edit",
                getRedirectURL: (id) => {
                    return `/admin/form/${FORM_KEY}/:id`
                },
            },
            {
                template: "delete",
                onClick: (id) => {
                    deleteItem(id);
                },
            },
        ],
    });

    useEffect(() => {
        setSearch(searchParams.get("search"));
    }, [searchParams]);

    useEffect(() => {
        setLatestSearchQuery(search);
    }, [search]);

    useEffect(() => {
        updateTableInfo(0);
    }, [latestSearchQuery, perPage]);

    return (
        <div className="page-content py-3 px-4 bg-lightgray">
            <ImportFilePopup upload_endpoint={`${API_HOST}/import`} />
            <ListingPageControls
                exportApi={export_product_templates}
                type="product"
                addNewLink="/admin/form/product-template"
                hasTitle={false}
                resultType="product"
                setSearch={setSearchParams}
            />
            <ItemListingTable
                fields={fields}
                data={products}
                pagination={pagination}
                updateTableInfo={updateTableInfo}
                setPerPage={setPerPage}
                perPage={perPage}
            />
        </div>
    );
};

export default Page;
