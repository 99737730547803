// a page component that takes in a param {key}, and calls the DynamicInput component

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { fetch_form_fields, save_form_data } from "../../../services/dynamic-form.ts";
import DynamicInput from "../DynamicInput"

type ModelInfo = {
    title: string,
    save_url: string,
    index_url: string
}

const Page = () => {
    const { key = '', id = '' } = useParams();
    const [fields, setFields] = useState([])
    const [fieldValues, setFieldValues] = useState({})
    const [modelInfo, setModelInfo] = useState<ModelInfo>({ title: "", save_url: "", index_url: "" });
    const [errorState, setErrorState] = useState({})

    const navigate = useNavigate()

    const handleInputFieldChange = (fieldName: string, fieldValue: any) => {
        setFieldValues({
            ...fieldValues,
            [fieldName]: fieldValue,
        })
    }

    const handleSave = () => {
        save_form_data(modelInfo.save_url, fieldValues).then((response) => {
            processValidationErrors(response, setErrorState)
            if (response.redirect_url !== undefined) {
                navigate(response.redirect_url)
            }
        })
    }

    useEffect(() => {
        fetch_form_fields({
            formKey: key,
            id: id
        }).then((response) => {
            if (response.data !== undefined) {
                setFields(response.data);
                setModelInfo({
                    title: response.model_title,
                    save_url: response.api_url,
                    index_url: response.index_redirect_url
                })

                let newValueFields = {}
                response.data.forEach((field) => {
                    newValueFields[field.key] = field.value
                })
                setFieldValues(newValueFields)
            }
        })
    }, [])

    return <div className="page-content py-3 px-4 bg-lightgray">
        <div className='w-100 d-flex flex-wrap space-between align-items-center'>
            <div className="mb-4">
                <span className="fw-bold dashboard-title">
                    {id !== '' ? "Edit" : "Add"} {modelInfo.title}
                </span>
                <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
                    <Link className={"link"} to={"/admin/"}>Dashboard</Link>
                    <span>&gt;</span>
                    <Link className={"link"} to={modelInfo.index_url}>{modelInfo.title}</Link>
                    <span>&gt;</span>
                    <span>{id !== '' ? `Edit #${id}` : `New ${modelInfo.title}`}</span>
                </div>
            </div>
            <Link to={modelInfo.index_url}>
                <button className='btn btn-primary my-2'>
                    Back
                </button>
            </Link>
        </div>
        <div className="form-renders">
            <div className="inputs-container bg-white mb-2 p-3">
                <div className="product-info form row">
                    {fields.map((field, index) => (
                        <DynamicInput
                            key={index + "-input"}
                            fieldData={field}
                            fieldValue={fieldValues[field.key]}
                            handleChange={handleInputFieldChange}
                            handleAttachmentChange={handleInputFieldChange}
                            containerClass={field.type.includes("image") ? "col-12 mb-4" : "col-6 mb-4"}
                            errorMessage={errorState[field.key] !== undefined ? errorState[field.key] : ""}
                        />
                    ))}
                </div>

                <div
                    className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
                    <button className={"btn btn-primary p-10-50"}
                        onClick={handleSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default Page;



const processValidationErrors = (response, setErrorState) => {
    const isValidationErrorResponse = (response.status === "error" && response.type === "validation");
    if (!isValidationErrorResponse) {
        setErrorState({});
        return;
    }

    let fieldErrorMsgMap = {}
    Object.keys(response.validations).forEach((key) => {
        const messages = response.validations[key]
        if (messages.length > 0) {
            fieldErrorMsgMap[key] = messages[0]; // Take the first error message for the field
        }
    });

    setErrorState(fieldErrorMsgMap);
};