const API_HOST = `${process.env.REACT_APP_DASHBOARD_API_URL}`;

type FetchFormOptions = {
    formKey: string,
    id?: string
}

const fetch_form_fields = (options: FetchFormOptions) => {
    const editURLPath = options.id ? `/edit/${options.id}` : '';
    const url = `${API_HOST}/api/dash/form/${options.formKey}${editURLPath}`;

    return fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

type FetchModelDataOptions = {
    formKey: string,
    id: string
}

const fetch_model_data = (options: FetchModelDataOptions) => {
    const url = `${API_HOST}/api/dash/form/${options.formKey}/${options.id}`;
    return fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

type BodyData = {
    [key: string]: any;
};

const save_form_data = (apiURL: string, apiData: BodyData) => {
    let data = new FormData()

    Object.keys(apiData).forEach((key) => {
        const value = apiData[key];
        if (Array.isArray(value)) {
            // Handle arrays by appending each item with indexed keys
            value.forEach((item, index) => {
                data.append(`${key}[${index}]`, item);
            });
        } else if (typeof value === 'object' && value !== null && !(value instanceof File) && !(value instanceof Blob)) {
            // Handle nested objects by stringifying
            data.append(key, JSON.stringify(value));
        } else {
            // Default case (handles files, Blobs, and primitive types)
            data.append(key, value);
        }
    });

    return fetch(`${API_HOST}${apiURL}`, {
        method: "POST",
        credentials: "include",
        body: data,
    }).then((res) => res.json());
}

export const delete_records = async (formKey: string, ids : string[]) => {

    const reqInit: RequestInit = {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ids: ids.join(",")})
    }
    return fetch(`${API_HOST}/api/dash/form/${formKey}`, reqInit).then((res) => res.json());
};


export {
    fetch_form_fields,
    fetch_model_data,
    save_form_data
}