import React, { useEffect, useState } from "react";
import DynamicInput from "../../DynamicInput.jsx";
import { fetch_app_settings, save_form_data } from "../../../../services/admin/app-settings.ts";
import { notify_promise } from "../../../../services/utils/toasts";

const Page = () => {
    const [settings, setSettings] = useState({})
    const [updatedSettings, setUpdatedSettings] = useState<{ [key: string]: any }>({});

    const onSave = () => {
        notify_promise(
            new Promise((resolve, reject) => {
                save_form_data(updatedSettings).then(res => {
                    resolve(res)
                })
                .catch(reject);
            })
          );
    };

    const onSettingChange = (key: string, value: any) => {
        const originalKey = key.replaceAll("_", ".")
        setUpdatedSettings({
            ...updatedSettings,
            [originalKey]: value
        })
    };

    useEffect(() => {
        fetch_app_settings().then(res => {
            if (res.data) {
                setSettings(res.data)
            }
        })
    }, [])

    return <div className="page-content py-3 px-4 bg-lightgray">
            <div className="form-renders">
                <div className="inputs-container bg-white mb-2 p-3">
                    <div className="product-info form row">
                        <h3>Pawpaws Application Settings</h3>
                        <p>In this page you can configure certain app behaviors and defaults</p>
                        <div className={"text-right"}>
                            <button disabled={false}
                                    type="button"
                                    className="btn btn-primary px-5"
                                    onClick={(e) => {
                                        onSave()
                                    }}>
                                Save
                            </button>
                        </div>
                        <SettingsTabs settings={settings} changeHandler={onSettingChange}/>
                    </div>
                </div>
            </div>
    </div>
}

const SettingsTabs = ({settings, changeHandler}) => {
    if (Object.keys(settings).length === 0) {
        return <div className="text-center">
            Loading Settings
        </div>
    }

    return (
        <div>
            {/* Nav tabs */}
            <ul className="nav nav-tabs" id="settingsTabs" role="tablist">
                {Object.keys(settings).map((key, index) => (
                    <li className="nav-item" role="presentation" key={key}>
                        <button
                            className={`nav-link ${index === 0 ? "active" : ""}`}
                            id={`${key}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#${key}`}
                            type="button"
                            role="tab"
                            aria-controls={key}
                            aria-selected={index === 0}
                        >
                            {settings[key].title}
                        </button>
                    </li>
                ))}
            </ul>

            {/* Tab content */}
            <div className="tab-content" id="settingsTabContent">
                {Object.keys(settings).map((key, index) => (
                    <div
                        className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
                        id={key}
                        role="tabpanel"
                        aria-labelledby={`${key}-tab`}
                        key={key}
                    >
                        <div className="p-3">
                            {settings[key].inputs.map((input, idx) => (
                                <div className="mb-3" key={input.key}>
                                    <DynamicInput
                                        key={idx + "-input"}
                                        fieldData={input}
                                        fieldValue={input.value}
                                        handleChange={changeHandler}
                                        handleAttachmentChange={changeHandler} 
                                        containerClass={undefined} 
                                        errorMessage={undefined}                                    
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Page;