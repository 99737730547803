import PhoneInput from "react-phone-number-input";
import React from "react";
import SingleFileUploader from "../SingleFileUploader";
import SinglePhotoUpload from "./common/SinglePhotoUpload.tsx";
import AvailabilitySettings from "../DayAvailabilityInput";

const DynamicInput = ({fieldValue, fieldData, handleChange, handleAttachmentChange, containerClass, errorMessage}) => {
    if (containerClass === undefined) {
        containerClass = ""
    }

    if (errorMessage === undefined) {
        errorMessage = ""
    }

    const label = (fieldData.label === "" || fieldData.label === undefined) ? "" :
        <label className={"fw-semibold"}>{fieldData.label}</label>

    const description = (fieldData.description === "" || fieldData.description === undefined) ? "" :
        <p className={"text-grey"}>{fieldData.description}</p>

    return <div className={containerClass}>
        {label}
        {description}
        <Input fieldValue={fieldValue} fieldData={fieldData} handleChange={handleChange}
               handleAttachmentChange={handleAttachmentChange}/>
        {errorMessage !== "" ? <p className="text-danger">{errorMessage}</p> : ""}
    </div>
};

const Input = ({fieldValue, fieldData, handleChange, handleAttachmentChange}) => {
    const {label, value, type, required, name, placeholder, options} = fieldData;
    const handleInputChange = (e) => {
        handleChange(e.target.name, e.target.value)
    }

    switch (type) {
        case "text":
        case "number":
        case "email":
            return (
                <input
                    type={type}
                    name={name}
                    defaultValue={fieldValue}
                    required={required}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                />
            );
        case "password":
            return (
                <input
                    type={type}
                    name={name}
                    value={fieldValue}
                    required={required}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    autoComplete="new-password"
                />
            );
        case "phone":
            return (
                <PhoneInput
                    defaultCountry="AE"
                    international
                    withCountryCallingCode
                    value={fieldValue || ""}
                    onChange={(val) => {
                        handleChange(name, val);
                    }}
                    name={name}
                />
            );
        case "textarea":
            return (
                <textarea
                    value={fieldValue || ""}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    name={name}
                />
            );
        case "select":
            return (
                <select
                    placeholder={placeholder}
                    name={name}
                    defaultValue={value}
                    required={required}
                    onChange={handleInputChange}
                >
                    {options.map((option, index) => (
                        <option value={option.value} key={index}>
                            {option.label}
                        </option>
                    ))}
                </select>
            );
        case "image":
            return (
                <SingleFileUploader
                    options={{
                        name: name,
                        currentPhoto: value,
                        onPhotoChange: handleAttachmentChange,
                    }}
                    type="image"
                />
            );
        case "image.v1":
            return (
                <SinglePhotoUpload
                    options={{
                        name: name,
                        onPhotoChange: handleAttachmentChange, // Callback for file selection
                        currentPhoto: value,
                    }}
                    type="image" // Specify "image" or "file"
                />
            );
        case "pdf":
            return (
                <SingleFileUploader
                    options={{
                        name: name,
                        currentPhoto: value,
                        onPhotoChange: handleAttachmentChange,
                    }}
                    type="pdf"
                />
            );
        case "day-availability":
            return (
                <AvailabilitySettings
                    initialAvailability={value}
                    onChange={(updatedAvailability) => {
                        handleChange(name, updatedAvailability);
                    }}
                />
            );
        default:
            return null;
    }
}

export default DynamicInput