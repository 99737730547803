import React, { useState, useEffect } from 'react';
import { fetch_model_data } from '../../../services/dynamic-form.ts';
import { Link, useParams } from 'react-router-dom';

const Page = () => {
    const { key = '', id = '' } = useParams();
    const [modelData, setModelData] = useState({});
    const [pageData, setPageData] = useState({
        model_title: '',
        index_redirect_url: ''
    });

    useEffect(() => {
        fetch_model_data({
            formKey: key,
            id: id
        }).then(response => {
            if (response.status == "ok") {
                setModelData(response.data);
                setPageData({
                    model_title: response.model_title,
                    index_redirect_url: response.index_redirect_url,
                })
            }
        })
            .catch(error => {
                console.error('Error fetching data:', error.message);
            });
    }, []);

    return (
        <div className="container">
            {Object.keys(modelData).length > 0 ?
                <ModelDataView modelData={modelData}
                    title={pageData.model_title}
                    redirectURL={pageData.index_redirect_url} />
                :
                <p>No data available.</p>
            }
        </div >
    );
}


const ModelDataView = ({ modelData, title, redirectURL }) => {
    const imageAttrNames = ["image", "picture"]
    const keysToExclude = ["id", "slug", "created_at", "updated_at", "deleted_at"]

    // make picture appear first in obj (@TODO make this dynamic by changing API response structure to list of info items instead of an object)
    const sortedObj = Object.keys(modelData).sort((a, b) => {
        if (['image', 'picture'].includes(a.toLowerCase())) return -1;
        if (['image', 'picture'].includes(b.toLowerCase())) return 1;
        return 0;
    }).reduce((acc, key) => ({ ...acc, [key]: modelData[key] }), {});

    return <div className='p-3'>
        <div className='w-100 d-flex flex-wrap space-between align-items-center'>
            <div className="mb-4">
                <span className="fw-bold dashboard-title">
                    {title}
                </span>
                <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
                    <Link className={"link"} to={"/admin/"}>Dashboard</Link>
                    <span>&gt;</span>
                    <Link className={"link"} to={redirectURL}>{title}</Link>
                    <span>&gt;</span>
                    <span>{`View #${modelData.id}`}</span>
                </div>
            </div>
            <Link to={redirectURL}>
                <button className='btn btn-primary my-2'>
                    Back
                </button>
            </Link>
        </div>
        <div className="row">
            <div className='col-md-12 bg-white p-3'>
                {Object.keys(sortedObj).map(key => {
                    if (keysToExclude.includes(key) || !modelData[key]) {
                        return <></>
                    }

                    const isImage = imageAttrNames.includes(key)
                    const keyTitle = key.replaceAll("_", " ").replace(/\b\w/g, match => match.toUpperCase());

                    return <div key={key}>
                        <div className="card">
                            <div className="p-2">
                                <h5 className="card-title text-black">{keyTitle}</h5>
                            </div>
                            <div className="card-body">
                                {isImage ? (
                                    <img src={modelData[key]} alt={key} className="img-fluid" />
                                ) : (
                                    <p>{modelData[key]}</p>
                                )}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default Page;