const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/app-settings";

const fetch_app_settings = async () => {
    const reqInit : RequestInit = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }

    return fetch(`${API_HOST}`, reqInit).then((res) => res.json());
};

type AppSettingsData = {
    [key: string]: any;
};

const save_form_data = (apiData: AppSettingsData) => {
    let data = new FormData()

    Object.keys(apiData).forEach((key) => {
        const value = apiData[key];
        if (Array.isArray(value)) {
            // Handle arrays by appending each item with indexed keys
            value.forEach((item, index) => {
                data.append(`${key}[${index}]`, item);
            });
        } else if (typeof value === 'object' && value !== null && !(value instanceof File) && !(value instanceof Blob)) {
            // Handle nested objects by stringifying
            data.append(key, JSON.stringify(value));
        } else {
            // Default case (handles files, Blobs, and primitive types)
            data.append(key, value);
        }
    });

    return fetch(`${API_HOST}`, {
        method: "POST",
        credentials: "include",
        body: data,
    }).then((res) => res.json());
}

export {
    fetch_app_settings, 
    save_form_data
}